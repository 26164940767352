import React from "react";
import useAppContext from "@hooks/useAppContext";
import useSession from "@hooks/useSession";
import HeaderProfile from "./HeaderProfile";
import Link from "next/link";
import Button from "@components/common/Button";
import ExitButton from "@components/icons/ExitButton";
import HeaderMenuItem from "./HeaderMenuItem";
import HeaderTripadvisorLogo from "@components/header/HeaderTripadvisorLogo";
import PhoneIcon from "@components/icons/PhoneIcon";
import {
  IMenuItemResponse,
  ITopNavigationResponse,
} from "@customTypes/commonResponses";

interface IMObileNavModalProps {
  topNavigation: ITopNavigationResponse;
  menuItems: IMenuItemResponse[];
}

function MobileNavModal({
  topNavigation,
  menuItems,
}: IMObileNavModalProps): JSX.Element {
  const appContext = useAppContext();
  const { session } = useSession();

  const phoneLink = "tel:" + topNavigation.Phone_Action;

  const closeParent = (): void => {
    appContext?.setShowHeaderMenuMobile(false);
  };
  const phoneStroke = "stroke-white";

  return (
    <div
      className={`${
        appContext.isPrerender ? "static" : "fixed"
      } left-0 top-0 z-50 flex h-[100%] w-[100%] flex-col justify-between overflow-auto bg-white ${
        appContext.showHeaderMenuMobile || appContext.isPrerender
          ? "block"
          : "hidden"
      }`}
    >
      <div className="z-100 sticky top-0 bg-white p-[0.875rem]">
        <ExitButton
          onClick={() => {
            closeParent();
          }}
        />
      </div>
      <div className="flex h-full flex-col gap-2 px-[0.875rem]">
        <ul>
          {menuItems.map((item, index) => (
            <HeaderMenuItem
              isMobile={true}
              isLastItem={index === menuItems.length - 1}
              isLogged={!!session}
              isModalOpened={appContext?.showHeaderMenuMobile}
              key={item.id}
              item={item}
            />
          ))}
        </ul>

        {/* The message should be displayed on home screen, not the modal
          {showLogoutMessage && (
            <Toast
              position="static"
              text={"You are now logged out."}
              onClose={() => setShowLogoutMessage(false)}
            />
          )} */}
        {session && (
          <HeaderProfile
            topNavigation={topNavigation}
            isMobile={true}
            closeParent={closeParent}
          />
        )}
        {topNavigation?.Show_Slogan && topNavigation?.TripAdvisor_Rating && (
          <HeaderTripadvisorLogo
            tripadvisorLogo={topNavigation?.TripAdvisor_Rating}
          />
        )}
        <p className="m-auto mb-4 flex h-[17px] w-[326px] justify-center text-[12px]">
          Customized Costa Rica Vacations, since 1999.
        </p>

        <Link
          className={session ? "pb-[0.875rem]" : undefined}
          href={phoneLink}
        >
          <Button variant="primary" onClick={() => {}}>
            <>
              <div className=" h-[1.125rem] w-[1.125rem]">
                <PhoneIcon phoneStroke={phoneStroke} />
              </div>
              &nbsp;{topNavigation.Phone_Visible}
            </>
          </Button>
        </Link>

        {!session && (
          <HeaderProfile
            topNavigation={topNavigation}
            isMobile={true}
            closeParent={closeParent}
          />
        )}
      </div>
    </div>
  );
}

export default MobileNavModal;
