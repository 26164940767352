import React, { useState } from "react";
import useAppContext from "@hooks/useAppContext";
import LoginForm from "./LoginForm";
import ConfirmForm from "./ConfirmForm";
import SideImage from "./SideImage";
// import AlternativeLogin from '@components/LoginModal/AlternativeLogin';
import { ImageDefault } from "@components/utils/Utils";
import Image from "next/legacy/image";
import ExitButton from "@components/icons/ExitButton";
import MediaAssets from "@components/utils/MediaAssets";
import AlternativeLogin from "./AlternativeLogin";
import ReactDom from "react-dom";
import { ITopNavigationResponse } from "@customTypes/commonResponses";
import { IVerifySignInResponse } from "@hooks/useAuth";

interface ILoginModalProps {
  topNavigation: ITopNavigationResponse;
  handleVerifySignIn: (code: string) => Promise<IVerifySignInResponse>;
  handleSignIn: (data: { email: string }) => Promise<void>;
  handleResendEmail: () => Promise<void>;
}

const LoginModal = ({
  topNavigation,
  handleVerifySignIn,
  handleSignIn,
  handleResendEmail,
}: ILoginModalProps): JSX.Element => {
  const appContext = useAppContext();
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const isPromptLogin = localStorage.getItem("IS_LOGIN_FROM_PROMPT");

  const closeModalHandler = (): void => {
    appContext?.setShowLoginModal(false);
    appContext?.setShowHeaderMenuMobile(false);
    localStorage.removeItem("IS_LOGIN_FROM_PROMPT");
  };

  return ReactDom.createPortal(
    <div className="md:inset-0 md:h-full fixed top-0 left-0 right-0 z-[100] flex h-full w-full flex-row justify-between overflow-y-auto overflow-x-hidden bg-white p-[1rem] desktop:p-[1.5rem]">
      {!appContext.isMobile && (
        <SideImage showConfirmScreen={showConfirmScreen} />
      )}
      <div className="flex w-full justify-center desktop:w-fit desktop:flex-grow desktop:justify-start">
        <div className="flex flex-col desktop:ml-[10%] desktop:max-w-[420px]">
          {!appContext.isMobile ? (
            <div className="">
              <Image
                src={ImageDefault(topNavigation?.Logo)}
                height="48"
                width="142"
                layout="fixed"
                alt="costa rican vacations logo"
              />
            </div>
          ) : (
            <div className="absolute top-[1rem] left-[1rem]">
              <Image
                src={MediaAssets.getAsset("frog_request_quote")}
                width="39"
                height="48"
                layout="fixed"
                alt="Request a quote"
              />
            </div>
          )}

          <h4 className="text-h7 my-4 text-center font-medium leading-[115%] desktop:mb-0 desktop:mt-[2rem] desktop:text-left desktop:text-h4 desktop:leading-[120%]">
            {!showConfirmScreen ? "Log In" : "Enter Verification Code"}
          </h4>

          {!showConfirmScreen ? (
            <div>
              <LoginForm
                setShowConfirmScreen={setShowConfirmScreen}
                handleSignIn={handleSignIn}
                isPromptLogin={isPromptLogin}
              />
              <AlternativeLogin isPromptLogin={isPromptLogin} />
            </div>
          ) : (
            <ConfirmForm
              handleVerifySignIn={handleVerifySignIn}
              handleResendEmail={handleResendEmail}
              isPromptLogin={isPromptLogin}
            />
          )}
        </div>
      </div>
      <div className="absolute top-[1rem] right-[1rem] desktop:top-[2.175rem] desktop:right-[2.175rem]">
        <ExitButton onClick={closeModalHandler} />
      </div>
    </div>,
    // @ts-expect-error
    document.getElementById("root-body")
  );
};

export default LoginModal;
