import React from "react";

interface IDividerProps {
  variant: "short" | "mobile";
}

const Divider = ({ variant }: IDividerProps): JSX.Element => {
  let width = "w-full";
  let visibility = false;
  if (variant === "short") {
    width = "ml-5 desktop:ml-0 w-full";
  } else if (variant === "mobile") {
    visibility = true;
  }

  return (
    <hr
      className={`${
        visibility ? "" : "hidden"
      } desktop:block ${width} border-blue-10`}
    ></hr>
  );
};

export default Divider;
