import React, { LegacyRef } from "react";
import HeaderMenuColumn from "./HeaderMenuColumn";
import ConditionalWrapper from "@components/common/ConditionalWrapper";
import Link from "next/link";
import { IMenuItemResponse } from "@customTypes/commonResponses";
// import ProfileIconWithName from "@components/ProfileIconWithName";

const COLUMNS = {
  ONE: "column_1",
  TWO: "column_2",
  THREE: "column_3",
};

const ALIGNMENT_LEFT: { [key: number]: any } = {
  0: { 300: "50%", 600: "150px", 900: "280px" },
  1: { 300: "50%", 600: "75px", 900: "230px" },
  2: { 300: "50%", 600: "50%", 900: "160px" },
  3: { 300: "50%", 600: "50%", 900: "100px" },
};

interface IHeaderSubmenuProps {
  showAccordion?: boolean;
  showDropdown?: boolean;
  arrowRef?: LegacyRef<HTMLDivElement>;
  item: IMenuItemResponse | any[] | any;
  index?: number;
  profile?: {
    fullName: string;
    name: string;
  };
  isMobile?: boolean;
}

const HeaderSubmenu = ({
  showAccordion,
  showDropdown,
  arrowRef,
  item,
  index,
  profile,
  isMobile,
}: IHeaderSubmenuProps): JSX.Element => {
  let result;
  let width: string | number = "300px";
  if (profile) {
    // PROFILE DROPDOWN

    result = (
      <>
        {/* SHOULD BE COMMENTED IN WHEN WE START USING USER NAME */}
        {/* {profile && (
          <ProfileIconWithName
            name={profile.name}
            icon={profile.icon}
            iconAlt={profile.iconAlt}
          />
        )} */}
        {Array.isArray(item) &&
          item.map((item: any, index) => (
            <ConditionalWrapper
              key={item.url}
              condition={!item.onClick}
              wrapper={(children) => (
                <Link href={item.url} prefetch={false}>
                  {children}
                </Link>
              )}
            >
              <div
                className="flex cursor-pointer justify-between font-medium text-black desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10"
                onClick={item.onClick}
              >
                <div>{item.title}</div>
                {item.count && (
                  <div className="rounded-3xl bg-blue-10 px-2">
                    {" "}
                    {item.count}{" "}
                  </div>
                )}
              </div>
            </ConditionalWrapper>
          ))}
      </>
    );
  } else {
    // ALL OTHER DROPDOWNS
    width = 0;
    if (showDropdown) {
      const columnOne = item?.children?.filter(
        (item: IMenuItemResponse) =>
          item.enum !== COLUMNS.TWO && item.enum !== COLUMNS.THREE
      );
      const columnTwo = item?.children?.filter(
        (item: IMenuItemResponse) => item.enum === COLUMNS.TWO
      );
      const columnThree = item?.children?.filter(
        (item: IMenuItemResponse) => item.enum === COLUMNS.THREE
      );

      result = [columnOne, columnTwo, columnThree].map((column, index) => {
        if (column && column.length > 0) {
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          width += 300;
          return (
            <div key={index} className="flex w-[300px] flex-col">
              {column.map((item: IMenuItemResponse) => (
                <HeaderMenuColumn key={item.id} item={item} />
              ))}
            </div>
          );
        }
        return column;
      });
      result = <div className="flex flex-row">{result}</div>;
    } else if (showAccordion) {
      result = item?.children?.map((item: IMenuItemResponse) => (
        <HeaderMenuColumn key={item.id} item={item} />
      ));
    }
  }

  return (
    <>
      {/* ARROW ABOVE DROPDOWN */}
      {showDropdown && (
        <div
          ref={arrowRef}
          // desktop/desktopLarge is fix until menu behaviour is decided
          className="shadow-3xl desktop:mt-[-7px] desktopLarge:mt-[0px]"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            top: profile ? "2.6rem" : "2.813rem",
            width: "0",
            height: "0",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderBottom: "10px solid #CFD3E5",
          }}
        ></div>
      )}
      {/* AREA ABOVE DROPDOWN SO IT DOESN"T LOSE HOVER */}
      {showDropdown && (
        <div className="absolute left-[50%] top-[0px] h-[10rem] w-[100%] translate-x-[-50%]" />
      )}
      <div
        className={`${
          !isMobile
            ? profile
              ? "dropdown-profile"
              : // desktop/desktopLarge is fix until menu behaviour is decided
                "dropdown-menu desktop:mt-[-7px] desktopLarge:mt-[0px]"
            : ""
        } ${
          showDropdown
            ? "dropdown show desktop:border-[1px] desktop:border-blue-10"
            : ""
        }`}
        id="submenu"
        style={
          showDropdown
            ? {
                left: profile
                  ? ""
                  : index && index < 4
                  ? ALIGNMENT_LEFT[index][width]
                  : "50%",
                width,
              }
            : undefined
        }
      >
        {result}
      </div>
    </>
  );
};

export default HeaderSubmenu;
