import React from "react";

const GoogleIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.93204 14.5284L6.23631 17.1272L3.69166 17.1814C2.92199 15.7479 2.51308 14.1485 2.50031 12.5215C2.48753 10.8945 2.87127 9.2889 3.61834 7.84351L5.88386 8.26011L6.87622 10.5098C6.42982 11.8152 6.44955 13.235 6.93204 14.5276V14.5284Z"
          fill="#10247E"
        />
        <path
          d="M22.3242 10.5735C22.6632 12.3563 22.5105 14.1978 21.8825 15.9005C21.2546 17.6032 20.1751 19.1029 18.7597 20.2388L15.9068 20.093L15.5027 17.5725C16.6807 16.8819 17.5857 15.8077 18.0665 14.5296H12.7197V10.5735H22.3251H22.3242Z"
          fill="#10247E"
        />
        <path
          d="M18.7583 20.2396C17.614 21.158 16.2816 21.8133 14.8557 22.1591C13.4298 22.505 11.9453 22.5329 10.5074 22.241C9.06947 21.949 7.7134 21.3443 6.53531 20.4697C5.35723 19.5951 4.38598 18.472 3.69043 17.1801L6.93081 14.5271C7.23516 15.3381 7.71301 16.0729 8.33094 16.68C8.94887 17.2871 9.69195 17.7519 10.5082 18.0419C11.3245 18.3319 12.1943 18.44 13.0567 18.3588C13.9192 18.2776 14.7535 18.009 15.5013 17.5717L18.7583 20.2396Z"
          fill="#10247E"
        />
        <path
          d="M18.8817 4.74458L15.6422 7.39671C14.8833 6.92407 14.0276 6.62863 13.1387 6.53237C12.2498 6.43611 11.3507 6.54151 10.5082 6.84073C9.66572 7.13996 8.90154 7.62529 8.27252 8.26064C7.6435 8.89599 7.16584 9.665 6.87507 10.5104L3.61719 7.84415C4.30399 6.51716 5.27948 5.36097 6.47193 4.46059C7.66437 3.56021 9.04346 2.93853 10.5078 2.64127C11.9721 2.34401 13.4844 2.37872 14.9336 2.74285C16.3827 3.10698 17.7318 3.79128 18.8817 4.74542V4.74458Z"
          fill="#10247E"
        />
      </svg>
    </div>
  );
};

export default GoogleIcon;
