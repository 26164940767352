import React from "react";

function ExitButton({ onClick }: any): JSX.Element {
  return (
    <div>
      <button
        className="flex h-[2.75rem] w-[2.75rem] items-center  justify-center rounded-2xl bg-blue-10 text-lightblue hover:bg-blue-20"
        onClick={() => onClick(false)}
      >
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <path
            d="M1.91357 11.6853L12.2845 1.3144"
            stroke="#10247E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
          <path
            d="M12.2847 11.6853L1.91377 1.3144"
            stroke="#10247E"
            strokeWidth="2.4"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  );
}

export default ExitButton;
