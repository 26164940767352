import React from "react";
import Link from "next/link";

interface IHeaderPhoneButtonProps {
  children: JSX.Element | JSX.Element[];
  phoneAction: string;
  phoneNumber: string;
}

const HeaderPhoneButton = ({
  children,
  phoneAction,
  phoneNumber,
}: IHeaderPhoneButtonProps): JSX.Element => {
  return (
    (<Link href={phoneAction} title={`Call ${phoneNumber}`}>

      <div className="tertiaryButtonColorScheme flex h-[44px] w-[44px] items-center justify-center whitespace-nowrap rounded-2xl bg-orange-10 desktop:h-[40px] desktop:w-fit desktop:rounded-full desktop:px-4">
        {children}
        <span className="hidden font-semibold desktop:block desktop:text-orange ">
          &nbsp;{phoneNumber}
        </span>
      </div>

    </Link>)
  );
};

export default HeaderPhoneButton;
