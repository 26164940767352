import React from "react";
import HeaderItems from "./HeaderItems";
import Link from "next/link";
import Logo from "@components/icons/Logo";
import {
  IMenuResponse,
  ITopNavigationResponse,
} from "@customTypes/commonResponses";
import { PageTypes } from "@customTypes/index";
import useAppContext from "@hooks/useAppContext";

export interface IHeaderProps {
  topNavigation: ITopNavigationResponse;
  menus: IMenuResponse;
  pageType: PageTypes;
}

const Header = ({
  topNavigation,
  menus,
  pageType,
}: IHeaderProps): JSX.Element => {
  const { isPrerender } = useAppContext();
  return (
    <header
      className={`${
        isPrerender ? "static h-fit" : "fixed h-[4.5rem]"
      } shadow-none top-0 z-[100] flex w-full bg-white desktop:h-[58px] desktop:border-b desktop:border-blue-10 ${
        pageType !== 1 ? "desktop:border-b-[0.5px] desktop:border-blue-10" : ""
      }`}
    >
      {/* MAIN CONTAINER */}
      <div className="relative mx-auto flex w-full flex-row items-center justify-between p-[0.875rem] desktop:my-auto desktop:max-w-[95rem] desktop:p-0 ">
        {/* LOGO */}
        <div className="absolute left-[calc(50%_-_71px)] top-[calc(50%_-_24px)] desktop:relative desktop:top-[4px] desktop:left-0 desktop:right-0 desktop:mr-4 desktopLarge:mr-6">
          {topNavigation?.Logo && (
            <Link href="/">
              <Logo />
            </Link>
          )}
        </div>
        {/* HEADER ITEMS */}
        <HeaderItems topNavigation={topNavigation} menus={menus} />
      </div>
    </header>
  );
};

export default Header;
