import React, { useState, useCallback, useEffect } from "react";
import useAppContext from "@hooks/useAppContext";
import useModalScrollFreeze from "hooks/useModalScrollFreeze";
import LoginModal from "../LoginModal/LoginModal";
import { useAuth } from "@hooks/useAuth";
import useSession from "@hooks/useSession";
import { useRouter } from "next/router";
import Button from "@components/common/Button";
import Link from "next/link";
import HeaderSubmenu from "./HeaderSubmenu";
import Heart from "@components/icons/Heart";
import { extractAndSanitizeName } from "@components/utils/sanitizeHelpers";
import { ITopNavigationResponse } from "@customTypes/commonResponses";

interface IHeaderProfile {
  topNavigation: ITopNavigationResponse;
  isMobile?: boolean;
  closeParent?: () => void;
}

const HeaderProfile = ({
  topNavigation,
  isMobile,
  closeParent,
}: IHeaderProfile): JSX.Element => {
  const appContext = useAppContext();
  const { signUp, signIn, verifySignIn, resendEmail, signOut } = useAuth();

  const [isHoveredProfile, setIsHoveredProfile] = useState(false);
  const { session } = useSession();
  const router = useRouter();

  useModalScrollFreeze([appContext?.showLoginModal]);

  const handleVerifySignIn = useCallback(async (code: string) => {
    const res = await verifySignIn(code);
    if (res.ok) {
      setIsHoveredProfile(false);
    }
    return res;
  }, []);

  const handleSignIn = useCallback(async (data: { email: string }) => {
    try {
      await signUp(data.email, "John Doe");
    } catch (e) {
      console.log("user is already signed up ", e);
    }
    await signIn(data.email);
  }, []);

  const handleLogout = useCallback(async () => {
    if (
      session?.idToken?.payload["cognito:username"]
        ?.toLowerCase()
        ?.includes("google") ||
      session?.idToken.payload["cognito:username"]
        ?.toLowerCase()
        ?.includes("facebook")
    ) {
      localStorage.setItem("REDIRECT_AFTER_SIGN_OUT", router.asPath);
      appContext?.setIsLoadingAuthAction((prevState) => {
        return { ...prevState, loggingOut: true };
      });
    }
    setIsHoveredProfile(false);
    await signOut();

    appContext?.setShowToastMessage(true);
    appContext?.setToastMessage("You are now logged out.");
    setTimeout(() => {
      appContext?.setShowToastMessage(false);
    }, 3000);
  }, [session, router]);

  const handleResendEmail = useCallback(async () => {
    await resendEmail();
  }, []);

  const [favCount, setFavCount] = useState(0);
  const [user, setUser] = useState({
    fullName: "",
    name: "",
  });

  const menu = [
    /* Hidden for initial Release - NAM-1687 */
    // {
    //   key: "saved_search",
    //   title: "Saved Searches",
    //   url: "#",
    //   count: savedSearchesCount,
    // },
    {
      key: "favorites",
      title: "Favorites",
      url: "/favorites",
      count: favCount,
    },
    {
      key: "logout",
      title: "Log Out",
      onClick: handleLogout,
    },
  ];

  useEffect(() => {
    if (session) {
      if (typeof window !== "undefined") {
        const accLikes = session.userPreferences?.accommodations;
        const actLikes = session.userPreferences?.activities;
        const pacLikes = session.userPreferences?.packages;
        const singlePacLikes = session.userPreferences?.locations;
        const totalCount =
          (accLikes?.length || 0) +
          (actLikes?.length || 0) +
          (pacLikes?.length || 0) +
          (singlePacLikes?.length || 0);

        setFavCount(totalCount);
      }
      setUser({
        fullName: extractAndSanitizeName(session, true),
        name: extractAndSanitizeName(session, false),
      });
    }
  }, [session]);

  const profileContent = isMobile ? (
    // MOBILE VIEW
    <ul className="mb-4 flex w-[100%] flex-col pt-4">
      <li className=" justify-betweendesktop:p-3 relative flex px-5 text-left text-[0.75rem] font-medium text-gray desktop:my-0 desktop:rounded-xl desktop:hover:bg-orange-10">
        {user.fullName}
      </li>
      {menu.map((option) => (
        <li
          key={option.title}
          className="relative px-5 text-left text-[1rem]"
          onClick={closeParent}
        >
          {option.url ? (
            <Link
              href={option.url}
              className={
                "flex justify-between py-3 font-medium text-black desktop:my-0 desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10"
              }
            >
              {option.title}
              <div className="flex h-[1.563rem] w-[1.563rem] items-center  justify-center rounded-md bg-white px-2 py-2 text-[0.75rem] font-medium text-lightblue">
                {option?.count || 0}
              </div>
            </Link>
          ) : (
            option.onClick && (
              <div
                className="flex justify-between py-3 font-medium text-black desktop:my-0 desktop:rounded-xl desktop:p-3 desktop:hover:bg-orange-10"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={option.onClick}
              >
                {option.title}
              </div>
            )
          )}
        </li>
      ))}
    </ul>
  ) : (
    // DESKTOP VIEW
    <div
      className="relative"
      onMouseEnter={() => setIsHoveredProfile(true)}
      onMouseLeave={() => setIsHoveredProfile(false)}
    >
      {/* @ts-expect-error */}
      {setIsHoveredProfile && (
        <Button variant="secondary-light" onClick={() => {}} size="small">
          {/* Extend profile box for hover events to work properly */}
          <div className="absolute h-[70px] w-full" />
          <div className="pr-2 desktop:border-r-[2px] desktop:border-blue-5">
            {user.name}
          </div>
          <div className="ml-2 flex flex-row items-center gap-2">
            <Heart />
            {favCount}
          </div>
        </Button>
      )}
      {isHoveredProfile && (
        <HeaderSubmenu showDropdown={true} item={menu} profile={user} />
      )}
    </div>
  );

  return (
    <>
      {session ? (
        <>{profileContent}</>
      ) : (
        <div className="w-full pb-[0.875rem] desktop:pb-0">
          <Button
            variant={isMobile ? "tertiary" : "secondary-light"}
            onClick={() => appContext?.setShowLoginModal(true)}
            size={isMobile ? "large" : "small"}
          >
            Log In
          </Button>
        </div>
      )}
      {/* The message should be displayed on home screen, not the modal
      {showLogoutMessage && (
        <Toast
          position="fixed"
          text={"You are now logged out."}
          onClose={() => setShowLogoutMessage(false)}
        />
      )} */}
      {appContext?.showLoginModal && (
        <div className="absolute">
          <LoginModal
            topNavigation={topNavigation}
            handleVerifySignIn={handleVerifySignIn}
            handleSignIn={handleSignIn}
            handleResendEmail={handleResendEmail}
          />
        </div>
      )}
    </>
  );
};

export default HeaderProfile;
