import React, { useState } from "react";
import Spinner from "@components/common/Spinner";
import { useAuth } from "@hooks/useAuth";
import GoogleIcon from "@components/icons/GoogleIcon";
import FacebookIcon from "@components/icons/FacebookIcon";

interface IAlternativeLoginProps {
  isPromptLogin: string | null;
}

const AlternativeLogin = ({
  isPromptLogin,
}: IAlternativeLoginProps): JSX.Element => {
  const awaitingInitialState = {
    google: false,
    facebook: false,
    apple: false,
  };
  const { googleSignIn, facebookSignIn } = useAuth();
  const [isAwaiting, setIsAwaiting] = useState(awaitingInitialState);

  const providers = [
    {
      id: isPromptLogin ? "login-google-prompt" : "login-google",
      logo: <GoogleIcon />,
      name: "Google",
      onClick: async () => {
        setIsAwaiting((prevState) => {
          return { ...prevState, google: true };
        });
        await googleSignIn();
        setTimeout(() => {
          setIsAwaiting(awaitingInitialState);
        }, 2000);
      },
    },
    {
      id: isPromptLogin ? "login-facebook-prompt" : "login-facebook",
      logo: <FacebookIcon />,
      name: "Facebook",
      onClick: async () => {
        setIsAwaiting((prevState) => {
          return { ...prevState, facebook: true };
        });
        await facebookSignIn();
        setTimeout(() => {
          setIsAwaiting(awaitingInitialState);
        }, 2000);
      },
    },
    // Apple object hidden
    // {
    //   id: "login-apple",
    //   logo: "apple_icon",
    //   name: "Apple",
    //   onClick: () => {
    //     setIsAwaiting((prevState) => {
    //       return { ...prevState, apple: true };
    //     });
    //   },
    // },
  ];

  const providerButtons = (): JSX.Element => (
    <div>
      {providers.map((provider) => (
        <button
          id={provider.id}
          key={provider.name}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={provider.onClick}
          className="relative my-2 mx-auto flex  w-[90%] items-center justify-center gap-[0.375rem] rounded-full bg-blue-10 py-4 font-semibold text-lightblue desktop:w-[100%]"
        >
          {provider.logo}
          {provider.name}
          {((provider.id === "login-google" && isAwaiting.google) ||
            (provider.id === "login-facebook" && isAwaiting.facebook) ||
            (provider.id === "login-apple" && isAwaiting.apple)) && (
            <div className="absolute right-6">
              <Spinner
                width={24}
                height={24}
                mainColor={"#10247E"} // the orange color from the theme
                backgroundColor={"#F3F4F8"} // the gray color from the theme
                position={"center"}
              />
            </div>
          )}
        </button>
      ))}
    </div>
  );

  return (
    <div className="mb-999 flex flex-col">
      <div className="relative flex items-center py-5">
        <div className="border-gray-400 flex-grow border-t"></div>
        <span className="text-gray-400 mx-4 flex-shrink">Or log in with</span>
        <div className="border-gray-400 flex-grow border-t"></div>
      </div>
      {providerButtons()}
    </div>
  );
};

export default AlternativeLogin;
