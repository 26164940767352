import React, { useState, useCallback, FormEvent } from "react";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import { IVerifySignInResponse } from "@hooks/useAuth";
import useAppContext from "@hooks/useAppContext";

const regexNumFilter = /[^0-9]/gi;
const CONFIRMATION_LENGTH = 6;

interface IConfirmFormProps {
  handleVerifySignIn: (code: string) => Promise<IVerifySignInResponse>;
  handleResendEmail: () => Promise<void>;
  isPromptLogin: string | null;
}

const ConfirmForm = ({
  handleVerifySignIn,
  handleResendEmail,
  isPromptLogin,
}: IConfirmFormProps): JSX.Element => {
  const appContext = useAppContext();
  const initialDigits = [];
  for (let i = 1; i <= CONFIRMATION_LENGTH; i++) {
    initialDigits.push("");
  }

  const [digits, setDigits] = useState(initialDigits);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [isAwaiting, setIsAwaiting] = useState(false);

  const onPasteHandler = useCallback((event: any) => {
    setErrorMessage("");
    event.preventDefault();
    const pasted = event?.clipboardData
      ?.getData("text/plain")
      .slice(0, CONFIRMATION_LENGTH);
    const pastedArray = pasted?.split("").slice(0, digits.length);

    // Making sure that new State is not less than 6 digits
    if (pastedArray && pastedArray.length < CONFIRMATION_LENGTH) {
      for (let i = pastedArray.length; i < CONFIRMATION_LENGTH; i++) {
        pastedArray.push("");
      }
    }

    setDigits(pastedArray as string[]);
  }, []);

  const onSubmitHandler = useCallback(
    async (e?: FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      setIsAwaiting(true);
      if (digits.join("").length >= CONFIRMATION_LENGTH) {
        const res = await handleVerifySignIn(digits.join(""));
        if (res.ok) {
          appContext?.setShowLoginModal(false);
          appContext?.setShowHeaderMenuMobile(false);
        } else if (isBlocked) {
          setErrorMessage(
            "You have entered an invalid code 5 times, please request a new one and try again."
          );
        } else if (
          res.failAuth &&
          res.message === "Invalid session for the user."
        ) {
          setErrorMessage("Your code has expired. Please request a new one.");
        } else if (
          res.failAuth &&
          res.message === "Incorrect username or password."
        ) {
          setIsBlocked(true);
          setErrorMessage(
            "You have entered an invalid code 5 times, please request a new one and try again."
          );
        } else {
          setErrorMessage("Invalid code, please try again.");
        }
      } else {
        setErrorMessage("Please enter all 6 digits.");
      }
      setIsAwaiting(false);
      localStorage.removeItem("IS_LOGIN_FROM_PROMPT");
    },
    [digits, isBlocked, isAwaiting]
  );

  const onInputHandler = useCallback(
    (event: any, index: number) => {
      setErrorMessage("");

      const cleanedValue = event.target.value.replaceAll(regexNumFilter, "");

      setDigits([
        ...digits.slice(0, index),
        cleanedValue,
        ...digits.slice(index + 1),
      ]);

      if (cleanedValue.length === 0) {
        // length check because of backspace
      } else if (event.target.nextElementSibling) {
        event.target.nextElementSibling.focus();
        event.target.nextElementSibling.setSelectionRange(0, 0);
      }
    },
    [digits]
  );

  const onKeyDownHandler = useCallback(
    (event: any, index: number) => {
      setErrorMessage("");

      if (event.keyCode === 37) {
        // arrow-left
        if (event?.target?.previousElementSibling) {
          event.preventDefault();
          event.target.previousElementSibling.focus();
          event.target.previousElementSibling.setSelectionRange(0, 0);
        }
      } else if (event.keyCode === 13) {
        // ENTER KEY
        event.preventDefault();
        onSubmitHandler().catch(() => {});
      } else if (event.keyCode === 39) {
        // arrow-right
        if (event.target.nextElementSibling) {
          event.preventDefault();
          event.target.nextElementSibling.focus();
          event.target.nextElementSibling.setSelectionRange(0, 0);
        }
      } else if (event.target.selectionStart === 0) {
        if (event.keyCode === 8 && event.target.previousElementSibling) {
          // backspace deletes previous field
          event.preventDefault();
          setDigits((prevState) => [
            ...prevState.slice(0, index - 1),
            "",
            ...prevState.slice(index),
          ]);
          event.target.previousElementSibling.focus();
        } else if (event.target.value.length > 0) {
          // changes the value of current field if beginning of selection
          setDigits((prevState) => [
            ...prevState.slice(0, index),
            "",
            ...prevState.slice(index + 1),
          ]);
        }
      } else if (event.target.selectionStart === 1) {
        if (event.keyCode === 46 && event.target.nextElementSibling) {
          event.preventDefault();
          setDigits((prevState) => [
            ...prevState.slice(0, index + 1),
            "",
            ...prevState.slice(index + 2),
          ]);
        } else if (event.keyCode === 8) {
          // just do the default
        } else if (event.target.nextElementSibling) {
          event.target.nextElementSibling.focus();
          // changes the value of the next field
          setDigits((prevState) => [
            ...prevState.slice(0, index + 1),
            "",
            ...prevState.slice(index + 2),
          ]);
        }
      }
    },
    [digits, setDigits]
  );

  const digitInputs = digits.map((digit, index) => (
    <input
      key={index}
      name={`verificationCode[${index}]`}
      className="digit-field"
      type="tel" // type tel forces android to use numeric keyboard as default and still have "text" properties
      maxLength={1}
      pattern="\d*"
      value={digit}
      onKeyDown={(event) => {
        onKeyDownHandler(event, index);
      }}
      onPaste={onPasteHandler}
      onInput={(event) => {
        onInputHandler(event, index);
      }}
      autoFocus={index === 0 ? true : undefined}
    />
  ));

  return (
    <form
      noValidate
      className="flex h-full flex-col justify-between overflow-y-auto desktop:justify-start"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmitHandler}
    >
      <div className="relative flex-grow">
        <div className="mt-12 flex flex-row justify-center">{digitInputs}</div>
        {errorMessage && (
          <div className="error-text absolute mt-1 flex text-[12px]">
            {errorMessage}
          </div>
        )}
      </div>

      <div className="bottom-[0px] left-[0px] flex w-full flex-col bg-white p-5  desktop:static desktop:mt-5 desktop:p-[0px]">
        <div className="mb-6 w-full text-center font-medium desktop:text-left">
          Didn’t receive the code?
          <span
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async (e) => {
              e.preventDefault();
              await handleResendEmail();
            }}
            className="ml-1 cursor-pointer font-light text-orange underline decoration-dashed underline-offset-4 hover:text-orange desktop:text-orange-100"
          >
            Resend&nbsp;Email
          </span>
        </div>
        <Button
          variant="primary"
          text={isAwaiting ? "" : "Log In"}
          id={isPromptLogin ? "login-btn-prompt" : "login-btn"}
          type="submit"
        >
          {/* @ts-expect-error */}
          {isAwaiting && (
            <Spinner
              width={24}
              height={24}
              mainColor={"#F55B46"} // the orange color from the theme
              backgroundColor={"#F3F4F8"} // the gray color from the theme
              position={"center"}
            />
          )}
        </Button>
      </div>
    </form>
  );
};

export default ConfirmForm;
