import React from "react";
import useAppContext from "@hooks/useAppContext";
import MobileNavModal from "./MobileNavModal";
import HeaderMenuBar from "./HeaderMenuBar";
import HeaderContactProfile from "./HeaderContactProfile";
import { IHeaderProps } from "./Header";

const HeaderItems = ({
  topNavigation,
  menus,
}: Pick<IHeaderProps, "topNavigation" | "menus">): JSX.Element => {
  const { isMobile } = useAppContext();
  return (
    <div className="my-auto flex h-full w-full flex-row items-center justify-between">
      <HeaderMenuBar
        menuItems={
          menus.menus.filter((item) => item.title === "Top menu")[0].items
        }
      />
      <HeaderContactProfile
        phoneAction={"tel:" + topNavigation?.Phone_Action}
        phoneNumber={topNavigation?.Phone_Visible}
        topNavigation={topNavigation}
      />
      {isMobile && (
        <MobileNavModal
          topNavigation={topNavigation}
          menuItems={
            menus.menus.filter((item) => item.title === "Top menu")[0].items
          }
        />
      )}
    </div>
  );
};

export default HeaderItems;
