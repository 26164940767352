import { useRouter } from "next/router";
import { useContext } from "react";
import useSession from "./useSession";
import { FiltersContext } from "providers/FiltersProvider";
import { useLike } from "@hooks/useLike";
import { setCookie } from "cookies-next";

export interface IVerifySignInResponse {
  ok: boolean;
  failAuth?: boolean;
  message?: string;
}

interface IUseAuthHookResponse {
  signUp: (email: string, fullName: string) => Promise<void>;
  signIn: (email: string) => Promise<void>;
  googleSignIn: () => Promise<void>;
  facebookSignIn: () => Promise<void>;
  verifySignIn: (answer: string) => Promise<IVerifySignInResponse>;
  resendEmail: () => Promise<void>;
  signOut: () => Promise<void>;
}

export const useAuth = (): IUseAuthHookResponse => {
  // @ts-expect-error
  const { clearAllValues } = useContext(FiltersContext);
  const { resetLikes } = useLike();
  const { refreshSession } = useSession();
  const router = useRouter();

  const sendAnalyticsData = (providerType: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "LoginSuccess",
      loginType: providerType,
    });
  };

  const signUp = async (email: string, fullName: string): Promise<void> => {
    const { Auth } = await import("aws-amplify");
    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        name: fullName,
      },
    };
    await Auth.signUp(params);
  };

  function getRandomString(bytes: number): string {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }

  function intToHex(nr: number): string {
    return nr.toString(16).padStart(2, "0");
  }

  // Track authentication flow state in this object
  const signIn = async (email: string): Promise<void> => {
    const { Auth } = await import("aws-amplify");

    const signInRes = await Auth.signIn(email);
    const { Session, authenticationFlowType, challengeName, username } =
      signInRes;
    sessionStorage.setItem(
      "TEMP_USER",
      JSON.stringify({
        Session,
        authenticationFlowType,
        challengeName,
        username,
      })
    );
  };

  const verifySignIn = async (
    answer: string
  ): Promise<IVerifySignInResponse> => {
    const { Auth } = await import("aws-amplify");

    // @ts-expect-error
    const user = Auth.createCognitoUser(
      JSON.parse(sessionStorage.getItem("TEMP_USER") ?? "{}").username
    );
    const temp = JSON.parse(sessionStorage.getItem("TEMP_USER") ?? "{}");
    user.Session = temp.Session;
    user.authenticationFlowType = temp.authenticationFlowType;
    user.challengeName = temp.challengeName;

    // Send the answer to the User Pool
    // This will throw an error if it’s the 5th wrong answer
    // It we get here, the answer was sent successfully,
    // but it might have been wrong previous attempts
    // So we should test if the user is authenticated now
    let authChallengeRes;
    try {
      authChallengeRes = await Auth.sendCustomChallengeAnswer(user, answer);
    } catch (e: any) {
      if (e.code === "NotAuthorizedException") {
        return { ok: false, failAuth: true, message: e.message };
      } else {
        return { ok: false, failAuth: true };
      }
    }

    try {
      // This will throw an error if the user is not yet authenticated:
      await Auth.currentSession();
      sessionStorage.removeItem("TEMP_USER");
      await refreshSession();
      sendAnalyticsData("email");
      return { ok: true };
    } catch {
      const { Session, authenticationFlowType, challengeName, username } =
        authChallengeRes;
      sessionStorage.setItem(
        "TEMP_USER",
        JSON.stringify({
          Session,
          authenticationFlowType,
          challengeName,
          username,
        })
      );
      return { ok: false };
    }
  };

  const resendEmail = async (): Promise<void> => {
    const { Auth } = await import("aws-amplify");

    const { username: currentSessionEmail } = JSON.parse(
      sessionStorage.getItem("TEMP_USER") ?? "{}"
    );
    const signInRes = await Auth.signIn(currentSessionEmail);
    const { Session, authenticationFlowType, challengeName, username } =
      signInRes;
    sessionStorage.setItem(
      "TEMP_USER",
      JSON.stringify({
        Session,
        authenticationFlowType,
        challengeName,
        username,
      })
    );
  };

  const googleSignIn = async (): Promise<void> => {
    const { Auth } = await import("aws-amplify");

    localStorage.setItem("REDIRECT_AFTER_LOGIN", router.asPath);
    setCookie("_isLogIn", true);
    // @ts-expect-error
    await Auth.federatedSignIn({ provider: "Google" });
    sendAnalyticsData("google");
  };

  const facebookSignIn = async (): Promise<void> => {
    const { Auth } = await import("aws-amplify");

    localStorage.setItem("REDIRECT_AFTER_LOGIN", router.asPath);
    setCookie("_isLogIn", true);
    // @ts-expect-error
    await Auth.federatedSignIn({ provider: "Facebook" });
    sendAnalyticsData("facebook");
  };

  const signOut = async (): Promise<void> => {
    const { Auth } = await import("aws-amplify");

    try {
      resetLikes();
      clearAllValues();
      await Auth.signOut();
      refreshSession().catch((e) => {});
    } catch (e) {}
  };

  return {
    signUp,
    signIn,
    googleSignIn,
    facebookSignIn,
    verifySignIn,
    resendEmail,
    signOut,
  };
};
