import React from "react";
import Image from "next/legacy/image";
import MediaAssets from "@components/utils/MediaAssets";

interface ISideImageProps {
  showConfirmScreen: boolean;
}

const SideImage = (showConfirmScreen: ISideImageProps): JSX.Element => {
  return (
    <div className="relative h-[calc(100vh_-_3.5rem)] w-[40%] pr-6">
      {!showConfirmScreen.showConfirmScreen ? (
        <Image
          src={MediaAssets.getAsset("login_step_1")}
          layout="fill"
          className="h-full w-full rounded-3xl object-cover"
          alt="login step 1"
        />
      ) : (
        <Image
          src={MediaAssets.getAsset("login_step_2")}
          layout="fill"
          className="h-full w-full rounded-3xl object-cover"
          alt="login step 2"
        />
      )}
    </div>
  );
};

export default SideImage;
