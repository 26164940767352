import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "@components/common/Button";
import { useForm } from "react-hook-form";
import { PATTERNS } from "@components/utils/constants";
import Spinner from "@components/common/Spinner";

const MAX_LENGTH_EMAIL = 320;
const regexEmailFilter = /[^a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.@]/gi;

interface ILoginFormProps {
  setShowConfirmScreen: Dispatch<SetStateAction<boolean>>;
  handleSignIn: (data: { email: string }) => Promise<void>;
  isPromptLogin: string | null;
}

const LoginForm = ({
  setShowConfirmScreen,
  handleSignIn,
  isPromptLogin,
}: ILoginFormProps): JSX.Element => {
  const [isAwaiting, setIsAwaiting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name) {
        if (value[name].length > MAX_LENGTH_EMAIL) {
          value[name] = value[name].slice(0, MAX_LENGTH_EMAIL);
          setValue(name, value[name]);
        }

        // Below is not else case because if pasted it has to be validated as well
        if (name === "email") {
          const cleanedValue = value.email.replaceAll(regexEmailFilter, "");
          setValue("email", cleanedValue);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const submitHandler = async (data: any): Promise<void> => {
    setIsAwaiting(true);
    if (!isAwaiting) {
      await handleSignIn(data);
      setShowConfirmScreen(true);
    }
  };

  return (
    <form
      noValidate // removes default browser validation
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(submitHandler)}
      className="flex flex-grow flex-col items-center justify-between desktop:justify-start"
    >
      <div className="flex flex-col">
        <div className="text-gray-400 flex pb-6 pt-4 text-center text-[0.9rem] desktop:px-0 desktop:text-left">
          Start planning your trip to Costa Rica by saving your favorites.
          It’s&nbsp;free!
        </div>
        <div className="input-label">Email Address</div>
        <input
          type="email"
          id="email"
          placeholder="Enter email"
          className={`input-field ${errors.email ? "error-field" : ""}`}
          maxLength={MAX_LENGTH_EMAIL}
          {...register("email", {
            required: { value: true, message: "Email field is required" },
            pattern: {
              value: PATTERNS.EMAIL_VALIDATION_PATTERN,
              message: "Please enter valid email address",
            },
          })}
        />
        {errors.email && (
          <span className="error-text">{errors.email.message}</span>
        )}
        <div className="m-5 text-center text-[0.9rem] font-medium">
          We&apos;ll send a verification code to your email.
        </div>
      </div>

      <div className="bottom-10 left-[0px] flex w-full flex-col bg-white p-[20px] desktop:static desktop:p-[0px]">
        <Button
          variant="primary"
          text={isAwaiting ? "" : "Continue"}
          id={isPromptLogin ? "login-continue-prompt" : "login-continue"}
          type="submit"
        >
          {/* @ts-expect-error */}
          {isAwaiting && (
            <Spinner
              width={24}
              height={24}
              mainColor={"#F55B46"} // the orange color from the theme
              backgroundColor={"#F3F4F8"} // the gray color from the theme
              position={"center"}
            />
          )}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
