import React, { Suspense } from "react";
import useAppContext from "@hooks/useAppContext";
import HeaderMenuItem from "./HeaderMenuItem";
import BurgerMenu from "@components/icons/BurgerMenu";
import { IMenuItemResponse } from "@customTypes/commonResponses";
import dynamic from "next/dynamic";

const CSSLoader = dynamic(async () => await import("@components/CSSLoader"), {
  ssr: false,
});

interface IHeaderMenuItemsProps {
  menuItems: IMenuItemResponse[];
}

const HeaderMenuItems = ({ menuItems }: IHeaderMenuItemsProps): JSX.Element => {
  const { isMobile } = useAppContext();
  return (
    <>
      {isMobile ? (
        <BurgerMenu />
      ) : (
        <nav aria-label="Site Menu" className="hidden desktop:flex" id="navbar">
          <Suspense fallback={null}>
            <CSSLoader header />
          </Suspense>
          <ul className="flex list-none">
            {menuItems.map((menuItem, index) => {
              return menuItem.title !== undefined ? (
                <HeaderMenuItem item={menuItem} key={index} index={index} />
              ) : null;
            })}
          </ul>
        </nav>
      )}
    </>
  );
};

export default HeaderMenuItems;
