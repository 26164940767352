import React from "react";

const Heart = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.93227 4.21762C10.3493 3.37502 11.039 2.69821 11.8893 2.29718C12.7396 1.89616 13.7006 1.7945 14.616 2.00872C15.5314 2.22294 16.3474 2.74045 16.9315 3.47714C17.5156 4.21383 17.8334 5.1264 17.8332 6.06654C17.8332 10.6863 10.776 15.7643 9.49999 15.7643C8.30209 15.7643 1.1668 10.6863 1.1668 6.06654C1.15556 5.11818 1.46822 4.19436 2.05309 3.44774C2.63797 2.70112 3.46007 2.17638 4.38355 1.96023C5.30702 1.74409 6.2766 1.84947 7.13208 2.25897C7.98755 2.66846 8.67772 3.35757 9.08854 4.21241C9.08854 4.21241 9.26562 4.68115 9.52603 4.68115C9.78644 4.68115 9.93227 4.21762 9.93227 4.21762Z"
        stroke="#10247E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Heart;
